import axios from 'axios';
import { SERVER_URL } from 'redux/actions/config';
import { stopProcess } from 'redux/actions/process';
import {
	LOGIN_SUCCESS,
	GUEST_LOGIN,
	USER_LOADING,
	MODAL_LOGIN_ERROR,
	LOGOUT,
	RESET_ADMIN,
	RESET_PROCESS_STATE,
	RESET_STEPS,
	MODAL_RESET,
	RESET_ADJUSTMENTS_STATE,
	SET_EMPLOYEE,
	SET_ADMIN_AUTH,
	SET_ASSOCIATED_REDEMPTION_CENTER,
	RESET_INTERFACE_STATE,
} from 'redux/actions/types';
import { configHeader } from 'utils/configHeader';
import { setLoading } from 'redux/actions/userInterface';
import { getDailyWeightLimits, finalize } from 'redux/actions/process';
import { t } from 'i18next';

export const login = (email, password) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		// TODO: user_loading might be redundant, need to look into
		dispatch({
			type: USER_LOADING,
		});

		const config = configHeader();
		const body = JSON.stringify({ email, password });

		axios
			.post(`${SERVER_URL}/auth/reduced_login`, body, config)
			.then((res) => {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: res.data,
				});
				dispatch(getDailyWeightLimits());
				resolve();
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: MODAL_LOGIN_ERROR,
					payload: {
						message: t('messages.modal.noEmailExists'),
						heading: t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

export const employeeLogin = (email, password) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();
		const body = JSON.stringify({ email, password });

		axios
			.post(`${SERVER_URL}/auth/`, body, config)
			.then((res) => {
				const employee = res.data.tenant_employee;

				if (employee) {
					dispatch({
						type: SET_EMPLOYEE,
						payload: {
							username: email,
							token: res.data.access_token,
						},
					});
					dispatch({
						type: SET_ADMIN_AUTH,
						payload: true,
					});
					resolve();
				} else if (!employee) {
					reject();
				}
			})
			.catch(() => {
				dispatch(setLoading(false));
				reject();
			});
	});
};

export const signUpUser = (email, phone, password) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const shortcode = getState().admin.tenantShortcode;
		const config = configHeader();

		const body = {
			email: email,
			phone_number: phone,
			password,
			from_tenant_shortcode: shortcode,
		};

		!email && delete body.email;
		!phone && delete body.phone_number;

		axios
			.post(`${SERVER_URL}/customer/reduced_signup`, body, config)
			.then(() => {
				resolve();
			})
			.catch((err) => {
				dispatch({
					type: MODAL_LOGIN_ERROR,
					payload: {
						message: t('messages.modal.couldNotCreateAccount'),
						heading: t('messages.modal.error'),
					},
				}),
					reject();
			});
	});
};

export const signUpUserFull = (userInfo) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const body = JSON.stringify(userInfo);
		const config = configHeader();

		axios
			.post(`${SERVER_URL}/customer/`, body, config)
			.then((res) => {
				resolve();
			})
			.catch((err) => {
				console.error(err);
				reject();
			});
	});
};

export const guestLogin = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();

		axios
			.post(`${SERVER_URL}/customer/create_guest`, config)
			.then((res) => {
				dispatch({
					type: GUEST_LOGIN,
					payload: true,
				});
				dispatch({
					type: LOGIN_SUCCESS,
					payload: res.data,
				});
				resolve();
			})
			.catch((err) => {
				dispatch({
					type: MODAL_LOGIN_ERROR,
					payload: {
						message: t('messages.modal.couldNotLoginAsGuest'),
						heading: t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

// gets a captcha code for email and phone verification
export const verificationRequest = (email, phoneNumber) => (dispatch) => {
	return new Promise((resolve, reject) => {
		let data;

		if (email && phoneNumber) {
			data = { email: email, phone_number: phoneNumber };
		} else if (email && !phoneNumber) {
			data = { email };
		} else if (!email && phoneNumber) {
			data = { phone_number: phoneNumber };
		}

		const body = JSON.stringify(data);
		const config = configHeader();

		axios
			.post(`${SERVER_URL}/customer/verification_request`, body, config)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const verificationStart = (captchaAnswer, captchaId) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const body = JSON.stringify({ id: captchaId, answer: captchaAnswer });
		const config = configHeader();
		axios
			.post(`${SERVER_URL}/customer/verification_start`, body, config)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const emailAndPhoneVerification = (emailVerification, phoneVerification) => () => {
	const config = configHeader();

	const emailBody = JSON.stringify({
		id_email_auth: emailVerification.id,
		verification_code: emailVerification.code,
	});

	const phoneBody = JSON.stringify({
		id_phone_number_auth: phoneVerification.id,
		verification_code: phoneVerification.code,
	});

	const phone = phoneVerification.code;
	const email = emailVerification.code;
	const phoneUrl = `${SERVER_URL}/customer/verify_phone_number_code`;
	const emailUrl = `${SERVER_URL}/customer/verify_email_code`;

	let status;

	return new Promise(async (resolve, reject) => {
		if (phone && email) {
			const emailReq = axios.post(emailUrl, emailBody, config);
			const phoneReq = axios.post(phoneUrl, phoneBody, config);

			await Promise.all([emailReq, phoneReq])
				.then(() => {
					status = 'success';
				})
				.catch((err) => {
					status = 'error';
				});
		} else if (!email && phone) {
			await axios
				.post(phoneUrl, phoneBody, config)
				.then(() => {
					status = 'success';
				})
				.catch((err) => {
					status = 'error';
				});
		} else if (email && !phone) {
			await axios
				.post(emailUrl, emailBody, config)
				.then(() => {
					status = 'success';
				})
				.catch(() => {
					status = 'error';
				});
		}

		if (status === 'success') {
			resolve();
		} else if (status === 'error') {
			reject();
		}
	});
};

export const getTenantList = () => (dispatch, getState) => {
	const config = configHeader();
	return new Promise((resolve, reject) => {
		axios
			.get(`${SERVER_URL}/tenant/shortcodes`, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.data);
			});
	});
};

export const getTenantByShortcode = (code) => (dispatch) => {
	const config = configHeader();
	return new Promise((resolve, reject) => {
		axios
			.get(`${SERVER_URL}/tenant/get_by_shortcode/${code}`, config)
			.then((res) => {
				dispatch({
					type: SET_ASSOCIATED_REDEMPTION_CENTER,
					payload: code,
				});
				resolve(res.data.business_name);
				console.log('get tenant shortcode working');
			})
			.catch((err) => {
				console.log('err:', err);
				console.log('get tenant shortcode NOT working');
				reject();
			});
	});
};

export const logout = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const taskId = getState().process.task_id?.task_id;
		const prevTaskId = getState().process.prevTaskId;
		const transactionFinalized = getState().process.finalized;
		const canFinalize = !transactionFinalized && (taskId || prevTaskId);

		taskId && dispatch(stopProcess());
		if (canFinalize) dispatch(finalize()); // IMPORTANT: generates a ledger entry
		localStorage.removeItem('state');
		dispatch({ type: LOGOUT });
		dispatch({ type: RESET_PROCESS_STATE });
		dispatch({ type: RESET_ADJUSTMENTS_STATE });
		dispatch({ type: RESET_STEPS });
		dispatch({ type: MODAL_RESET }); // just in case
		dispatch({ type: RESET_ADMIN });
		dispatch({ type: RESET_INTERFACE_STATE });
		resolve(); // if slow internet, make sure logout function is completed before user returns to login page
	});
};

export const logoutEmployee = () => (dispatch) => {
	dispatch({
		type: SET_EMPLOYEE,
		payload: {
			token: '',
			username: '',
		},
	});
};

