// lib
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Suspense } from 'react';

// components
import SnackbarAndModalController from 'components/SnackbarAndModalController';
import { getSoftwareVersion } from 'redux/actions/admin';
import { getPaymentData } from 'redux/actions/user';
import InactivityTimer from 'components/InactivityTimer';
import DonateToCharity from 'pages/Donate/DonateToCharity';
import LanguageToggle from 'components/Background/LanguageToggle';
import NoConnection from 'components/NoConnection';
import NotFoundPage from 'pages/Errors/NotFoundPage';
import ItemProcess from 'pages/Process/itemProcess';
import Promotion from 'pages/Promotion/Promotion';
import FullSignUp from 'pages/SignUp/FullSignUp';
import LoginPage from 'pages/Login/LoginPage';
import ExpressV2 from 'pages/Express/ExpressV2';
import Checkout from 'pages/Checkout/Checkout';
import DemoMode from 'components/DemoMode/DemoMode';
import ThankYou from 'pages/ThankYou/ThankYou';
import SignUp from 'pages/SignUp/SignUp';
import Admin from 'pages/Admin/Admin';
import theme from 'themes/theme';

// lib and utils
import { configEnvironmentSettings, isDevelopmentEnv, initSentry } from 'utils/environment';
import { disableRightClick } from 'lib';
import { interceptors } from 'utils/interceptors';

// dev page
// import DevelopmentSandbox from 'pages/Development/DevelopmentSandbox';

initSentry();

function App() {
	const dispatch = useDispatch();
	const dryrun = useSelector((state) => state.process.dryrun);

	const itemsProcessingRoutes = [
		'/begin-process',
		'/item-detect',
		'/eco-vision',
		'/start-process',
		'/payment-select',
		'/print-receipt',
		'/machine-paused',
	];

	interceptors.response;
	interceptors.request;

	useEffect(() => {
		configEnvironmentSettings();
	}, [dryrun]);

	useEffect(() => {
		!isDevelopmentEnv() && disableRightClick();
		dispatch(getPaymentData());
		dispatch(getSoftwareVersion());
	}, []);

	return (
		<Suspense fallback='loading...'>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<SnackbarAndModalController />
				<InactivityTimer />
				<NoConnection />
				<LanguageToggle />
				<Router>
					<DemoMode />
					<Switch>
						<Route exact path='/'>
							<LoginPage />
						</Route>
						<Route path='/sign-in' component={LoginPage} />
						<Route path='/sign-up' component={SignUp} />
						<Route path='/full-sign-up' component={FullSignUp} />
						<Route path={itemsProcessingRoutes} component={ItemProcess} />
						<Route path={'/express'} component={ExpressV2} />
						<Route path={'/checkout'} component={Checkout} />
						<Route path={'/donate'} component={DonateToCharity} />
						<Route path={'/thank-you'} component={ThankYou} />
						<Route path={'/admin'} component={Admin} />
						<Route path={'/promotion'} component={Promotion} />
						{/* <Route path={'/sandbox'} component={DevelopmentSandbox} /> */}
						<Route>
							<NotFoundPage />
						</Route>
					</Switch>
				</Router>
			</ThemeProvider>
		</Suspense>
	);
}

export default App;
