import { isMobileUnique, isUniqueEmail } from 'API/validation';
import i18next from 'i18next';

export const screenEmailAndPhone = async (email, phoneNumber) => {
	const isEmailUnique = email ? await isUniqueEmail(email) : true;
	const isPhoneUnique = phoneNumber ? await isMobileUnique(phoneNumber) : true;
	const areFieldsNotEmpty = (email || phoneNumber) !== '';
	const isEmailFormatValid = email ? email.includes('@') : true;
	const isPhoneLengthValid = phoneNumber ? phoneNumber.match(/\d/g).length === 10 : true;

	const checks = {
		noEmptyFields: areFieldsNotEmpty,
		phoneLengthValid: isPhoneLengthValid,
		emailFormatValid: isEmailFormatValid,
		emailUnique: isEmailUnique,
		phoneUnique: isPhoneUnique,
	};

	const status = {
		ok: true,
		message: '',
	};

	Object.keys(checks).map((check) => {
		if (checks[check] === false) {
			status.ok = false;
			status.message = i18next.t('mobileSignup.errorUserInfo');
		}
	});

	return status;
};

export const passwordRequirements = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])(?=.{8,})/,
);
