import { combineReducers } from 'redux';
import { auth } from './auth';
import { process } from './process';
import { modal } from './modal';
import metrics from './metrics';
import steps from './steps';
import admin from './admin';
import { userInterface } from './userInterface';
import { adjustments } from './adjustments';

export const rootReducer = combineReducers({
	auth,
	admin,
	modal,
	process,
	steps,
	metrics,
	adjustments,
	userInterface,
});
