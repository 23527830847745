import { RESET_INTERFACE_STATE, SET_LOADING } from '../actions/types';

const initialState = {
	loading: false,
};

export const userInterface = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case RESET_INTERFACE_STATE:
			return initialState;
		default:
			return state;
	}
};
